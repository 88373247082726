<template>
    <div>
      <div class="fatherBox">
          <div class="headerBox">
              <p @click="$router.go(-1)" class="stulist">学生列表</p><p class="sprit">/</p><p class="StuNameies">{{StuName}}</p>
          </div>

        <div style="display:flex;margin-top:20px;margin-left:30px;">
          <el-input v-model="StuName" placeholder="请输入姓名"  size="small" style="width:200px;"></el-input>
          <el-radio-group v-model="stuEditsex" style="margin: 11px 20px 0;">
            <el-radio  label="1">男</el-radio>
            <el-radio  label="0">女</el-radio>
          </el-radio-group>
          <el-button @click="studentEdit()" size="small">确认修改</el-button>
        </div>

      <div class="headinfo">
          <div style="display:flex">
              <p>编号:{{serial}}</p>
              <p>左眼{{lefteyes}}</p>
              <p>右眼{{righteyes}}</p>
<!--              <p>双眼{{binoculus}}</p> -->
          </div>
        <div class="headinfo_title"><p>上次测试时间：{{TestTimes}}</p></div>
        <!-- <p style="margin-left:30px">上次测试地点：{{site}}</p> -->
      </div>
        <div id="main" style="width: 100%; height: 400px; background: white"></div>
      </div>
    </div>
</template>
<script>
import { studentChart ,
        studentEdit,
} from '@/api/TeacherApi.js'
export default {
  data () {
    return {
      StuName: '', // 学生姓名
      serial: '1415132553', // 编号
      lefteyes: '', // 左眼
      righteyes: '', // 右眼
      binoculus: '5.5', // 双眼
      // site:'辽宁省大连市',// 上次测试地点
      TestTimes: '暂无', // 上次测试时间
      stuUserID: '', // 学生ID
      Dates: [], // 折线图X轴数据
      Leyes: [], // 折线图左眼数据
      Reyes: [], // 折线图右眼数据
      Deyes: [],// 折线图双眼数据
      stuID:"",// 学生id
      stuEditsex: '', // 学生要修改的性别
    }
  },
  mounted () {//页面一加载时候就执行这个
    this.stuEditsex= this.$route.params.StuSex
    this.stuUserID = this.$route.params.StuID // 给学生ID赋值 通过上个页面$route传递过来的
      //console.log(this.$route.params.StuID)
    this.studentChart() // 学生折线图数据
    // console.log(this.$route.params.StuID);

  },
  methods: {
    drawChart () {
      console.log(this)
      const myChart = this.$echarts.init(document.getElementById('main'))
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
        },
        legend: { data: ['左眼', '右眼'] },
        xAxis: {
          data: this.Dates
        },
        yAxis: {},
        series: [
          {
            name: '左眼',
            type: 'line',
            data: this.Leyes
          },
          {
            name: '右眼',
            type: 'line',
            data: this.Reyes
          },
          // {
          //   name: '双眼',
          //   type: 'line',
          //   data: this.Deyes
          // }
        ]
      }
      myChart.setOption(options)
    },
    studentEdit(){
      const data = {
         stuID:       this.$route.params.StuID ,// 学生ID
         stuName:     this.StuName ,// 学生name
         stuSex:      this.stuEditsex // 学生sex 通过在 标签定义ref属性 获取值
       }
      studentEdit(data).then(res => {
        //console.log(res)
        if (res.data  == 1) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        }else if(res.data  == 2) {
          this.$message({
            message: '姓名不能有特殊字符',
          })
          //this.$message.error(res.status.msg)
        }else if(res.data  == 0) {
          this.$message({
            message: '保存成功',
          })
          //this.$message.error(res.status.msg)
        }else{
          this.$message({
            message: '修改失败',
            type: 'error'
          })
        }
      }).catch(err => { console.log(err) })
    },

    studentChart () { // 获取学生个人折线图数据
      const data = {
        stuUserID: this.stuUserID // 学生ID
      }
      studentChart(data).then(res => {
       
        if (res.status.code == 1) {
          this.Dates = res.data.date // 日期
          this.Leyes = res.data.lEye// 左眼
          this.Reyes = res.data.rEye// 右眼
          this.Deyes = res.data.dEye // 双眼
          this.StuName = res.data.StuName// 学生姓名
          this.serial = res.data.StuNumber// 学生编号
          this.lefteyes = res.data.LastTestLEye// 学生左眼
          this.righteyes = res.data.LastTestREye// 学生右眼
          this.binoculus = res.data.LastTestDEye// 学生双眼
          if(res.data.LastTestTime){
            const newdate = res.data.LastTestTime.slice(0, 4) + '年' + res.data.LastTestTime.slice(5, 7) + '月' + res.data.LastTestTime.slice(8, 10) + '日';
            this.TestTimes = newdate// 上次测试时间
          }else{
            const newdate = '暂无'
            this.TestTimes = newdate// 上次测试时间
          }

          this.drawChart() // 执行折线图
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => { console.log(err) })
    },
  }
}
</script>

<style lang="scss" scoped>
    .fatherBox {
  width: 98%;
  display: flex;
  background: white;
  margin: 0 auto;
  flex-direction: column;
//   justify-content: center;
  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    border-bottom: 2px solid #e9e9e9;
    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
    //   color: #000000;
    }
    .stulist{
      color:#8C8C8C;
      cursor: pointer;
      margin: 0 3px 0 32px;
    }
    .sprit{
      float: left;
      color: unset;
      font-size: 17px;
    }
    .StuNameies{
    margin-left: 3px;
    color:#1890FF;
    cursor: pointer;
}
  }
  .headinfo{
       display: flex;
       flex-direction: column;
        p{
            text-align: left;
            margin-left: 33px;
        }
        // p:nth-child(1){
        //     font-size: 14px;
        //     font-family: PingFang SC;
        //     font-weight: 400;
        //     line-height: 22px;
        //     color: #8C8C8C;
        // }
      .headinfo_title{
       display: flex;
       p{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
       }
      }
  }
}

</style>
